import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    navBarMenu: false,
    userId: '',
    APPiD: '',
    profileCrated: false,
    restrictModal: {
      type: 1, // membership: 1 - profile: 2
      state: false,
      text: '¡Forma parte de Zuggy Club Gamers ahora y empieza a gozar de todos los beneficios de tu membresía! <br> <div class="tx-bold tx-glow my-1">Contrata ahora</div> Y un mes va por nuestra cuenta.',
      btnLabel: 'Comprar',
    },
    alertModalShopping: false,
    membership: false,
    profile: false,
    pagoMembresia: false,
    resultadoTransaccion: '',
    tipoDeCompra: '',
    productId: '',
    puntosUsuario: 0,
    renovacionMembresia: 0,
    usoDePuntos:  false,
    payMethod: 1
  },
  getters: {
    navBarMenuOpen(state){
      return state.navBarMenu;
    },
    userId(state){
      return state.userId;
    },
    restrictModal(state){
      return state.restrictModal;
    },
    restrictModalState(state){
      return state.restrictModal.state;
    },
    paymentModalState(state){
      return state.alertModalShopping;
    },
    isMember(state){
      return state.membership;
    },
    profileCompleted(state){
      return state.profile;
    },
    renewalMembership(state){
      return state.renovacionMembresia;
    },
  },
  mutations: {
    setClaroPayId(state){state.userId = localStorage.getItem('claro-pay-id')},
    toggleNavBarMenu(state) { state.navBarMenu = !state.navBarMenu; },
    toggleRestrictModal(state) { state.restrictModal.state = !state.restrictModal.state; },
    setMembership(state, payload) { state.membership = payload },
    setProfileCompleted(state, payload) { state.profile = payload },
    modalAlertShopping(state) { state.alertModalShopping = !state.alertModalShopping },
  },
  actions: {
    checkMembership({ state, commit }){
      return axios.post(process.env.VUE_APP_SERVICE_URL + `vigencia-membresia-clubgamer?plataforma_id=1&usuario_id=${state.userId}`).then(res => {
        console.log("checkMembership " + res.data);
        console.log(res.data);
        state.renovacionMembresia = res.data.expirada;
        commit('setMembership', res.data.error === 0);
      }).catch(error => { console.log(error) })
    },
    checkProfile({ state, commit }){
      return axios.post(process.env.VUE_APP_SERVICE_URL + `perfilamiento?plataforma_id=1&usuario_id=${state.userId}`).then(res => {
        commit('setProfileCompleted', res.data[0] != undefined);          
      }).catch(error => { console.log(error) })
    },
    updatePoints({ state, commit }){
      if(state.usoDePuntos){
        return axios.post(process.env.VUE_APP_SERVICE_URL + `puntos?plataforma_id=1&usuario_id=${state.userId}&puntos=${state.puntosUsuario}`).then(res => {  
          console.log("updatePoints");
          console.log(res.data);
          }).catch(error => { console.log(error) })
      }
    },
    recoverPayment({ state, commit }){
      if(state.tipoDeCompra == 'membresia'){
        console.log("In recoverPayment")
        console.log(state.resultadoTransaccion);
        return axios.post(process.env.VUE_APP_SERVICE_URL + `pago-membresia?plataforma_id=1&usuario_id=${state.userId}&producto_id=${localStorage.getItem('claro-tipo-id-producto')}&renovacion=${state.renovacionMembresia}&metodo_pago_id=1&datos_transaccion=${JSON.stringify(state.resultadoTransaccion)}`).then(res => {  
        console.log("Save membership " + res.data);
        }).catch(error => { console.log(error) })

      } else {
        return axios.post(process.env.VUE_APP_SERVICE_URL + `pago-producto?plataforma_id=1&usuario_id=${state.userId}&producto_id=${localStorage.getItem('claro-tipo-id-producto')}&metodo_pago_id=${state.payMethod}&datos_transaccion=${JSON.stringify(state.resultadoTransaccion)}`).then(res => {
          console.log("Guardo info del producto");
          console.log(res.data);
          }).catch(error => { console.log(error) })
      }
    }
  },
  modules: {},
});
