<script>
    import axios from 'axios'
    import { useVuelidate } from '@vuelidate/core'
    import { required } from '@vuelidate/validators'

    import UploadAvatar from '@/components/layout/UploadAvatar.vue'
    import MyButton from '@/components/ui/MyButton.vue'
    import HeaderComponent from '@/components/layout/HeaderComponent.vue'
    import ModalAlert from '@/components/ModalAlert.vue'
    import * as sdkclaro from "@claro/sdkclaro";

    export default {
        components: { UploadAvatar, MyButton, HeaderComponent, ModalAlert },
        setup () {
            return { v$: useVuelidate() }
        },
        data: () => ({
            userProfile: {},
            userName: '',
            userAvatar: '',
            userBirth: '',
            userPlatform: 'XBOX',
            userTypeGame: 'Carreras',
            userFavoriteStreamer: '',
            dataPlatform: [
                { id: 1, name: 'XBOX', icon: 'xbox' },
                { id: 2, name: 'PC', icon: 'pc' },
                { id: 3, name: 'Playstation', icon: 'ps' },
                { id: 4, name: 'Móvil', icon: 'mobile' },
            ],
            typeGames: [ 'Carreras', 'Aventura', 'Peleas', 'Disparos', 'Terror', 'Deportivos' ],
            editProfile: false,
            loading: false,
            modal: {
                state: false,
                description: '',
                type : ''
            },
        }),
        validations: () => ({
            userName: { 
                required,
            },
        }),
        methods: {
            async getProfile() {
                try {
                    const res = await axios.post(process.env.VUE_APP_SERVICE_URL + `perfilamiento?plataforma_id=1usuario_id=${this.$store.getters['userId']}`)
                    this.userProfile = res.data[0];
                    
                    console.log("Perfil")
                    console.log(this.userProfile);

                    this.userName = this.userProfile.nombre
                    this.userAvatar = this.userProfile.avatar
                    this.userBirth = this.userProfile.fecha_de_nacimiento
                    this.userPlatform = this.userProfile.plataforma
                    this.userTypeGame = this.userProfile.juegos
                    this.userFavoriteStreamer = this.userProfile.referencias
                } catch (err) { console.log(err) }
            },
            async submitForm(){
                const result = await this.v$.$validate()
                if (!result) return

                this.loading = true;
                try {
                    const res = await axios.post(process.env.VUE_APP_SERVICE_URL + 'update_perfil?plataforma_id=1', this.user);
                    this.loading = false;
                    this.modal = {
                        state: true,
                        description: '¡Muchas gracias! <br><br> <span class="tx-16 tx-regular">Datos guardados con éxito.</span>',
                        type: 'success',
                    }
                    console.log(res);
                } catch (err) { 
                    this.loading = false;
                    this.modal = {
                        state: true,
                        description: 'Ha ocurrido un error <br><br> <span class="tx-16 tx-regular">favor de intentarlo de nuevo</span>',
                        type: 'error',
                    }
                    console.log(err); 
                }
            },
            handleAccept(state){
                this.modal = false
                
                if (state === 'error'){
                    this.modal = false
                    return
                }
                this.$router.replace({ path: '/mi-perfil' })  
            },
            nameKeydown(e) {
                if (/^\W$/.test(e.key)) e.preventDefault();
            },
            handleEditProfile(){
                this.editProfile = true;
            }
        },
        watch: {
            userName(val) {
                this.userName = val.replace(/\W/g, "");
            },
        },
        computed: {
            user () {
                return {
                    "usuario_id"  : this.$store.getters['userId'],
                    "nombre"      : this.userName,
                    "fecha"       : this.userBirth,
                    "avatar"      : this.userAvatar,
                    "plataforma"  : this.userPlatform,
                    "juegos"      : this.userTypeGame,
                    "referencias" : this.userFavoriteStreamer,
                }
            },
        },
        mounted(){
            sdkclaro.getInstance().switchGoBackButton(true)
            this.getProfile()
            this.trackLog({ 
                action: 'Ingreso a editar perfil', 
                medio: 'Botón', 
                sectionId: 7, 
                elementId: null, 
            })
        },
        created(){
        }
    }
</script>
<template>
    <div class="create-profile-view app-view relative pt-30">
        <header-component>
            <h1 class="tx-24 tx-center mb-0">
                Editar Perfil
            </h1>
        </header-component>

        <!-- UPDATE PROFILE -->
        <div class="container">            
            <!-- UPLOAD AVATAR -->
            <!--
                <upload-avatar class="mb-3" :getAvatar="userProfile.avatar" v-model="userAvatar"/>
            -->
            
            <div class="form-group" :class="{'form-invalid' : v$.userName.$error}">
                <label class="form-label" for="">Ingresa tu nombre de usuario</label>
                <input class="form-input" type="text" placeholder="Nombre de usuario" maxlength="30" v-model.trim="userName" @keydown="nameKeydown($event)">
            </div>
            <div class="tx-14 opacity-08 tx-center mb-4 -mt-3" v-if="v$.userName.$error">
                Favor de escribir un nombre de usuario
            </div>

        
            <div class="form-group">
                <label class="form-label" for="">Fecha de nacimiento</label>
                <input class="form-input" type="date" placeholder="" maxlength="30" v-model="userBirth" @keydown="nameKeydown($event)">
            </div>

            <!-- PLATFORM -->
            <div class="box-form mb-4">
                <div class="box-form-header">
                    1. ¿Dónde sueles pasar más tiempo jugando?
                </div>
                <div class="box-form-content">
                    <div class="grid grid-cols-2 gap-x-4 gap-y-2 px-3">
                        <div class="form-radio flex align-center" v-for="(p, idx) in dataPlatform" :key="idx">
                            <input class="form-radio-input" type="radio" name="platform" :id="p.name" :value="p.name" v-model="userPlatform">
                            <label class="form-radio-label flex align-center" :for="p.name">
                                <div class="h-9 w-9 grid place-items-center mr-3 ml-1">
                                    <img :src="require(`@/assets/images/views/create_profile/icon-${p.icon}.svg`)" alt="">
                                </div>
                                {{ p.name }}
                            </label>
                        </div> 
                    </div>
                </div>
            </div>

            <!-- GAME -->
            <div class="box-form mb-4">
                <div class="box-form-header">
                    2. ¿Qué tipo de videojuegos prefieres?
                </div>
                <div class="box-form-content">
                    <div class="grid grid-cols-2 gap-x-4 gap-y-2 px-6">
                        <div class="form-radio flex align-center" v-for="(game, idx) in typeGames" :key="idx">
                            <input class="form-radio-input" type="radio" name="typeGame" :id="game" :value="game" v-model="userTypeGame">
                            <label class="form-radio-label flex align-center ml-2" :for="game">
                                {{ game }}
                            </label>
                        </div> 
                    </div>
                </div>
            </div>

            <div class="form-group">
                <label class="form-label" for="">3. ¿Cuál es tu streamer favorito?</label>
                <input class="form-input" type="text" placeholder="Espacio para respuesta" maxlength="30" v-model="userFavoriteStreamer">
            </div>
            
            <my-button label="Guardar Perfil" @clicked="submitForm()" :sectionId="7" action="Guardar perfil"/>

            <!-- LOADER -->
            <div class="overlay fixed grid place-items-center t-0 l-0 w-100pr h-100pr z-10" v-if="loading">
                <div class="loader-spinner"></div>
            </div>

            <!-- MODAL -->
            <transition name="fade">
                <modal-alert v-if="modal.state" @close="handleAccept(modal.type)">
                    <div class="p-4">
                        <p class="tx-22 tx-bold tx-center mb-9" v-html="modal.description"></p>
                        <button class="btn btn--success mx-auto flex" :class="modal.type === 'success' ? 'btn--success' : 'btn--danger'" @click="handleAccept(modal.type)">
                            Aceptar
                        </button>
                    </div>
                </modal-alert>
            </transition>
        </div>
    </div>
</template>