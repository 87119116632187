import Vue from "vue";
import VueRouter from "vue-router";

import LoginView from '@/views/LoginView.vue';

import WelcomeViewVue from "@/views/WelcomeView.vue";

import IntroductionView from "@/views/register/PurchaseMembershipView.vue";
import PayMethodView from "@/views/register/PayMethodView.vue";
import RegisteredView from "@/views/register/RegisteredView.vue";

import CreateProfileView from "@/views/user/CreateProfileView.vue";
import ProfileView from "@/views/user/ProfileView.vue";
import EditProfileView from "@/views/user/EditProfileView.vue";

import ShoppingHistoryView from "@/views/user/ShoppingHistoryView.vue";

import HomeLayoutView from "@/views/home/HomeLayoutView.vue";
import ZuggyGroundView from "@/views/home/ZuggyGroundView.vue";
import EventDetailView from "@/views/home/EventDetailView.vue";
import ForumDetailView from "@/views/home/ForumDetailView.vue"

import CommunityView from "@/views/home/CommunityView.vue";
import NewDetailTournamentsView from "@/views/home/NewDetailTournamentsView.vue";

import NewDetailView from "@/views/home/NewDetailView.vue";
import ExperienceDetailView from "@/views/home/ExperienceDetailView.vue";
import VideoDetailView from "@/views/home/VideoDetailView.vue";

import StoreView from "@/views/home/StoreView.vue";

import CategoryView from "@/views/store/CategoryView.vue";
import SubcategoryView from "@/views/store/SubcategoryView.vue";
import ProductDetailView from "@/views/store/ProductDetailView.vue";

import ScratchAndWinView from "@/views/ScratchAndWinView.vue";
import PoolsView from "@/views/PoolsView.vue";

import RewardsView from "@/views/RewardsView.vue";
import RewardDetailView from "@/views/RewardDetailView.vue"
import RewardDetailVivePlus from "@/views/RewardDetailVivePlus.vue"

import ChatView from "@/views/ChatView.vue";

import HelpView from "@/views/HelpView.vue";
import PreguntasFrecuentesView from "@/views/PreguntasFrecuentesView.vue";


import TermsView from "@/views/home/TermsView.vue";
import TermsViewInit from "@/views/home/TermsViewInit.vue";
import AvisoView from "@/views/home/AvisoView.vue";
import PoliticasView from "@/views/home/PoliticasView.vue";
import ForosView from "@/views/home/ForosView.vue";

import NotFoundView from "@/views/NotFoundView.vue";

Vue.use(VueRouter);

const routes = [
/*   {
    path: "/",
    name: "Login",
    component: LoginView,
  }, */
  {
    path: "/",
    name: "bienvenida",
    component: WelcomeViewVue,
  },
  {
    path: "/comprar-membresia",
    name: "Comprar membresia",
    component: IntroductionView,
  },
  {
    path: "/registro/metodo-de-pago",
    name: "metodo-de-pago",
    component: PayMethodView,
  },
  {
    path: "/registro/completado",
    name: "completado",
    component: RegisteredView,
  },
  {
    path: "/crear-perfil",
    name: "Crear perfil",
    component: CreateProfileView,
  },
  {
    path: "/mi-perfil",
    name: "Mi perfil",
    component: ProfileView,
  },
  {
    path: "/editar-perfil",
    name: "Editar perfil",
    component: EditProfileView,
  },
  {
    path: "/historial-de-compras",
    name: "Historial de compras",
    component: ShoppingHistoryView,
  },
  {
    path: "/inicio",
    name: "inicio",
    component: HomeLayoutView,
    children: [
      { name: 'Zona Premier' , path: 'arena-zuggy', component: ZuggyGroundView },
      { name: 'Detalle del evento' , path: '/evento/:id', component: EventDetailView },
      { name: 'Detalle del Foro' , path: '/foro/:id', component: ForumDetailView },
      { name: 'Comunidad' , path: 'comunidad', component: CommunityView },
      { name: 'Detalle de noticia', path: '/noticia/:id/:name', component: NewDetailView,
      },
      { name: 'Torneos', path: '/torneo/:id', component: NewDetailTournamentsView,
      },
      { name: 'Experiencia', path: '/experiencia/:id/:name', component: ExperienceDetailView,
      },
      { name: 'Tienda' , path: 'tienda', component: StoreView },
      { name: 'Términos y condiciones' , path: 'terminos', component: TermsView },
      { name: 'Aviso de privacidad' , path: 'aviso', component: AvisoView },
      { name: 'Política de reembolso de códigos digitales' , path: 'politicas', component: PoliticasView },
      { name: 'Políticas de foros' , path: 'foros', component: ForosView },
      { name: 'Video destacado', path: '/video/:id', component: VideoDetailView,
      },
    ]
  },
  {
    path: "/categoria/:categoryId/:name/:subcategoryId",
    name: "Categoria de tienda",  
    component: CategoryView,
  },
  {
    path: "/subcategorias/:name/:id",
    name: "Subcategoria de tienda",  
    component: SubcategoryView,
  },
  {
    path: "/producto/:id",
    name: "Detalle de producto",  
    component: ProductDetailView,
  },
  {
    path: "/rasca-y-gana",
    name: "rasca-y-gana",
    component: ScratchAndWinView,
  },
  {
    path: "/quiniela",
    name: "Quiniela",
    component: PoolsView,
  },
  {
    path: "/recompensas",
    name: "Recompensas",
    component: RewardsView,
  },
  ,
  {
    path: "/recompensa/:id",
    name: "Recompensa",
    component: RewardDetailView,
  },
  {
    path: "/chat",
    name: "chat",
    component: ChatView,
  },
  {
    path: "/asistencia",
    name: "Asistencia",
    component: HelpView,
  },
  {
    path: "/preguntas-frecuentes",
    name: "Preguntas frecuentes",
    component: PreguntasFrecuentesView,
  },
  {
    path: "/viveplus",
    name: "Vive Plus",
    component: RewardDetailVivePlus,
  },
  {
    path: "/terminos-init",
    name: "Términos y condiciones",
    component: TermsViewInit,
  },
  {
    path: '/:pathMatch(.*)', component: NotFoundView,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
