<script>
    import BurgerBtn from '../ui/BurgerBtn.vue'
    import ControlsBackAndHome from './ControlsBackAndHome.vue'
    import NavBarMenu from './NavBarMenu.vue'

    export default {
        components: { ControlsBackAndHome, BurgerBtn, NavBarMenu },
        props: {
            burgerBtn: Boolean
        },
        methods: {
            goToBack(){ history.back() }
        }
    }
</script>
<template>
    <header class="main-header flex align-center relative fixed t-0 l-0 w-100pr z-10 pt-4 pb-3 px-5">
        <!-- BACK AND HOME -->
        <controls-back-and-home class="-ml-3 mb-1"/>

        <!-- TITLE -->
        <div>
            <slot></slot>
            <!-- DECORE -->
            <img class="decore-shine" src="@/assets/images/layout/shine-1.png" alt="">
        </div>
        
        <!-- BURGER BUTTON -->
        <div style="width: 80px;" >
         <burger-btn class="r-0 mr-4 mb-1" v-if="burgerBtn"/>
        </div>
    </header>
</template> 
<style lang="scss" scoped>
    .main-header {
        background-color: #3C0778;
    }

    h2{
        width: 100%;
    }
</style>
