<script>
    import moment from 'moment';

    export default {
        props: { data: Object },
        computed: {
            timeAgo(){
                return moment(this.data.fecha).fromNow();
            }
        },
        mounted() {
            moment.locale('es');
        }
    }
</script>

<template>
    <div class="comment-item flex">
        <div class="w-6 mr-3 flex-none">
            <img src="@/assets/images/views/community/icon-user-default.png" alt="">
            <!-- <div class="h-6 w-6 bg-center bg-cover bg-no-repeat rounded-100pr bordev" :style="{'background-image' : 'url(' + data.avatar + ')'}"></div> -->
        </div>
        <div class="pt-1">
            <div class="tx-12 mb-3">
                <span v-html="data.nombre"></span> &nbsp;&nbsp;·&nbsp;&nbsp;
                <span class="opacity-07" v-html="timeAgo"></span>
            </div>
            <p v-html="data.comentario"></p>
        </div>
    </div>
</template>