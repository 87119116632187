<script>
    import axios from 'axios';

    export default {
        data: () => ({
            current: {},
            fielType: '',
            videoUrl:'',
            like_por_usuario: false,
            likes: 0,

        }),     
        methods: {
            async getNew (){
                try{
                    const res = await axios.post(process.env.VUE_APP_SERVICE_URL + 'detalle-video?plataforma_id=1&id=' + this.$route.params.id);
                    this.current = res.data[0]
                    //this.likes = this.current.me_gusta
                    this.id = this.current.id
                    //this.fielType = this.current.imagen.split('.').pop()
                } catch (err) { console.log(err) }
            },
            async like(id){
                
                try{
                    const tipo = "videos"
                    const resa = await axios.post(process.env.VUE_APP_SERVICE_URL + 'me-gusta?plataforma_id=1&id=' + this.$route.params.id +'&tipo=' + tipo +'&usuario_id=' + this.$store.getters['userId']);
                    this.likes = resa.data.numero
                    this.like_por_usuario = !this.like_por_usuario
                } catch (err) { console.log(err) }
            },
            async likesTotal(){
                
                try{
                    const resa = await axios.post(process.env.VUE_APP_SERVICE_URL + 'me-gusta-total?plataforma_id=1&id=' + this.$route.params.id);
                    this.likes = resa.data.numero
                } catch (err) { console.log(err) }
            },
            async likeUser(){
                console.log('like Users')
                try{
                    const tipo = "videos"
                    const resa = await axios.post(process.env.VUE_APP_SERVICE_URL + 'me-gusta-por-usuario?plataforma_id=1&id=' + this.$route.params.id +'&tipo=' + tipo +'&usuario_id=' + this.$store.getters['userId']);
                    this.like_por_usuario = resa.data.usuario        
                } catch (err) { console.log(err) }
            },
        },
        mounted() {
            this.getNew();
            this.likesTotal();
            this.likeUser();
            this.trackLog({ 
                action: 'Ingreso a detalle de evento especial', 
                medio: 'Banner', 
                sectionId: 2, 
                elementId: this.$route.params.id, 
            })
        },
        beforeMount(){
            console.log("Before mounted")
            setTimeout(function () {  
                this.$refs.videoRef.src = this.current.video;
                this.$refs.videoRef.play();
            }.bind(this), 200)
            
        }
    }
</script>

<template>
    <div class="new-detail-view">
        <div class="container">
            <div class="relative mb-5">

                {{ this.videoUrl }}
                <video ref="videoRef" style="width: 100%;" class="rounded-10 border-1" controls>
                    <source type="video/mp4">
                    Your browser does not support HTML video.
                </video>
                <!--<img class="decore-shine absolute l-0 r-0 b-0 -mb-2 mx-auto" :src="current.poster" alt="">-->
            </div>

            <!--<div class="relative bg-center bg-cover bg-no-repeat h-55 w-100pr rounded-10 border-1 border-white mb-5" :style="{ 'background-image': 'url(' + current.imagen + ')' }" v-else>
                <img class="decore-shine absolute l-0 r-0 b-0 -mb-2 mx-auto" src="@/assets/images/layout/shine-banner.png" alt="">
            </div>-->

            <p class="tx-20 tx-bold" v-html="current.titulo"></p>
            <!-- BODY -->
            <div class="tx-14 tx-medium mb-6" v-html="current.descripcion"></div>
            <div>
                <button class="btn like-button" @click="like(current.id)" >
                    <span>
                    
                        <img v-if="this.like_por_usuario == true"  width="30" class="" src="@/assets/images/me_gusta_activo.png" alt=""> 
                        <img v-else width="30" class="" src="@/assets/images/me_gusta.png" alt=""> 
                        {{this.likes}}
                    </span>
                </button>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
    .like-button{
        margin:  0;
        background: transparent;
        border: 0;
        color: #fff;
        padding: 0
    }
    .decore-shine{ max-width: initial;}
</style>
