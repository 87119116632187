<script>
    import axios from "axios"

    import HeaderComponent from '@/components/layout/HeaderComponent.vue'
    import NavBarMenu from '@/components/layout/NavBarMenu.vue'
    import * as sdkclaro from "@claro/sdkclaro";
    
    export default {
        components: { HeaderComponent, NavBarMenu },
        data: () => ({
            render: false,
            zuggyBenefits: [],
            specialEvent: {},
            specialsEvents: [],
            bannerVive: null,
            swiperIkee: {
                allowTouchMove: true,
                slidesPerView: 1.9,
                centeredSlides: true,
                autoplay: {
                    delay: 3000,
                    disableOnInteraction: false,
                },
                effect: 'coverflow',
                coverflowEffect: {
                    rotate: 1,
                    stretch: 96,
                    depth: 100,
                    modifier: 1,
                    slideShadows: true,
                }
            },
            swiperBig: {
                spaceBetween: 40,
                allowTouchMove: true,
                slidesPerView: 1.3,
                centeredSlides: true,
                autoplay: {
                    delay: 3000,
                    disableOnInteraction: false,
                },
            },
        }),
        methods: {
            async getZuggyBenefits (){
                try{
                    const res = await axios.post(process.env.VUE_APP_SERVICE_URL + 'beneficios-zuggy?plataforma_id=1');
                    this.zuggyBenefits = res.data;
                } catch (err) { console.log(err) }
            },
            async getSpecialEvent (){
                try{
                    const res = await axios.post(process.env.VUE_APP_SERVICE_URL + 'eventos-especiales?plataforma_id=1');
                    console.log('getSpecialEvent')
                    console.log(res.data)
                    this.specialsEvents = res.data;
                } catch (err) { console.log(err) }
            },
            async getBannerVive(){
                try{
                    const res = await axios.post(process.env.VUE_APP_SERVICE_URL + 'banner-vive-plus?plataforma_id=1');
                    this.bannerVive = res.data;
                } catch (err) { console.log(err) }
            },
            goToSpecialEvent(){
                this.trackLog({ 
                    action: 'Click evento especial', 
                    medio: 'Banner', 
                    sectionId: 9, 
                    elementId: null, 
                })

                window.open(this.specialEvent.url, '_blank');
            },
            goToBenefit(path){
                console.log("Click en beneficio zuggy")
                this.trackLog({ 
                    action: 'Click beneficios zuggy', 
                    medio: 'Banner', 
                    sectionId: 9, 
                    elementId: null, 
                })

                this.$router.push({ path: path })
            },
            goToFeaturedUrl(url){
                this.trackLog({ 
                    action: 'Click eventos destacados', 
                    medio: 'Banner', 
                    sectionId: 9, 
                    elementId: null, 
                })

                window.open(url, '_blank');
            },
            goToNews(path){
                this.trackLog({ 
                    action: 'Click  Experiencias Zuggy Club Gamers', 
                    medio: 'Banner', 
                    sectionId: 2, 
                    elementId: null, 
                })

                this.$router.push({ path: path })
            },
            goToVivePlus(path){
                this.trackLog({ 
                    action: 'Click beneficios zuggy', 
                    medio: 'Banner', 
                    sectionId: 9, 
                    elementId: null, 
                })

                this.$router.push({ path: path })
            },
        },
        mounted(){
            sdkclaro.getInstance().switchGoBackButton(true);
            this.getZuggyBenefits();
            this.getSpecialEvent();
            this.getBannerVive();

            setTimeout(() => this.render = true, 0);
            
            this.trackLog({ 
                action: 'Ingreso a recompensas', 
                medio: 'Botón', 
                sectionId: 9, 
                elementId: null, 
            })
        }
    }
</script>
<template>
    <div class="rewards-view app-view pt-28">
        <header-component burgerBtn>
            <h2 class="tx-24 tx-bold tx-center mb-0">
                Recompensas
            </h2>
        </header-component>

        <div class="container relative">
            <p>
                Promociones del mes
            </p>
            <div class="relative mb-6">
                <div class="rounded-10 border-1" @click="goToVivePlus(`/viveplus`)">
                    <img class="rounded-10" :src="bannerVive.imagen" alt="">
                </div>
            </div>

            <p class="mb-0">
                Beneficios Zuggy Club Gamers
            </p>
            <!-- SWIPER -->
            <swiper class="swiper mb-6" :options="swiperIkee" ref="swiperZuggy" v-if="render">
                <swiper-slide v-for="(item, idx) in zuggyBenefits" :key="idx">
                    <div class="item-layer relative z-0 flex align-end w-100pr h-40 relative bg-center bg-cover bg-no-repeat rounded-10 border-1 p-4" :style="{ 'background-image': 'url(' + item.imagen + ')' }" @click="goToBenefit(`/recompensa/${item.id}`)">
                        <div>
                            <h3 class="tx-14 mb-0 tx-medium">
                                {{ item.titulo }}
                            </h3>
                            <p class="tx-12 mb-0 max-h-3 overflow-hidden">
                                {{ item.sinapsis }}
                            </p>
                        </div>
                    </div>
                </swiper-slide>
            </swiper>
            <!-- LAYER RESTRICT -->
            <layer-restrict v-if="!$store.getters['isMember']"/>
        </div>
        <div class="container relative">
            <p>
                Experiencias Zuggy Club Gamers
            </p>

            <!--<div class="item-event flex align-end w-100pr h-50 relative bg-center bg-cover bg-no-repeat rounded-10 border-1 border-white p-4 z-0 overflow-hidden" :style="{ 'background-image': 'url(' + specialEvent.imagen + ')' }" @click="goToSpecialEvent()">
                <div class="max-w-75pr">
                    <h3 class="tx-14 mb-1 tx-medium">
                        {{ specialEvent.titulo }}
                    </h3>
                    <p class="tx-12 mb-0">
                        {{ specialEvent.sinapsis }}
                    </p>
                </div>
            </div>-->

            <swiper class="swiper mb-6" :options="swiperBig" ref="swiperBig">
                <swiper-slide v-for="(item, idx) in specialsEvents" :key="idx">
                    <!--a @click="goToFeaturedUrl(item.url)">-->
                        <div class="item-event flex align-end w-100pr h-40 relative bg-center bg-cover bg-no-repeat rounded-10 border-1 border-white p-4 z-0 overflow-hidden" :style="{ 'background-image': 'url(' + item.imagen + ')' }" @click="goToNews(`/experiencia/${item.id}/titulo`)">
                            <div class="max-w-75pr">
                                <h3 class="tx-14 mb-1 tx-medium">
                                    {{ item.titulo }}
                                </h3>
                                <p class="tx-12 mb-0 h-8 overflow-hidden">
                                    {{ item.sinapsis }}
                                </p>
                            </div>
                        </div>
                    <!--</a>-->
                </swiper-slide>
            </swiper>
        </div>

        <!-- <swiper class="swiper mb-6" :options="swiperBig" ref="swiperBig">
            <swiper-slide v-for="(item, idx) in specialEvents" :key="idx">
                <div class="item-event flex align-end w-100pr h-40 relative bg-center bg-cover bg-no-repeat rounded-10 border-1 border-white p-4 z-0 overflow-hidden" :style="{ 'background-image': 'url(' + item.imagen + ')' }" @click="goToEvent(`/evento-especial/${item.id}`)">
                    <div class="max-w-75pr">
                        <h3 class="tx-14 mb-1 tx-medium">
                            {{ item.titulo }}
                        </h3>
                        <p class="tx-12 mb-0 h-8 overflow-hidden">
                            {{ item.sinapsis }}
                        </p>
                    </div>
                </div>
            </swiper-slide>
        </swiper> -->

        <!-- NAV BAR MENU -->
        <transition name="nav-bar">
            <nav-bar-menu v-show="$store.getters['navBarMenuOpen']"/> 
        </transition>
    </div>
</template>
<style lang="scss" scoped>
    .swiper{
        padding-top: 18px!important;
        padding-bottom: 18px!important;
    }

    .swiper-slide{
        transition: transform 400ms ease;
    }
    .swiper-slide-active{
        transform: scale(1.2);
    }

    .item-event::after,
    .next-event::after{
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        //background-image: linear-gradient(rgba(#000, 0), rgba(#000, 0.2), rgba(#000, 1));
        z-index: -1;
    }
</style> 