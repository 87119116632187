<script>
    import axios from 'axios'
    import { useVuelidate } from '@vuelidate/core'
    import { required } from '@vuelidate/validators'

    import UploadAvatar from '@/components/layout/UploadAvatar.vue'
    import MyButton from '@/components/ui/MyButton.vue'
    import HeaderComponent from '@/components/layout/HeaderComponent.vue'
    import * as sdkclaro from "@claro/sdkclaro";
    
    export default {
        components: { UploadAvatar, MyButton, HeaderComponent },
        setup () {
            return { v$: useVuelidate() }
        },
        data: () => ({
            edad: '',
            userProfile: {},
            membresiaProfile: {},
        }),
        validations: () => ({
            userName: { 
                required,
            },
        }),
        methods: {
            async getProfile() {
                try {
                    const res = await axios.post(process.env.VUE_APP_SERVICE_URL + `perfilamiento?plataforma_id=1&usuario_id=${this.$store.getters['userId']}`)
                    this.userProfile = res.data[0];

                    var hoy = new Date();
                    var cumpleanos = new Date(res.data[0].fecha_de_nacimiento);
                    this.edad = hoy.getFullYear() - cumpleanos.getFullYear();

                } catch (err) { console.log(err) }
            },
            async getMembresiaProfile() {
                try {
                    const a = await axios.post(process.env.VUE_APP_SERVICE_URL + `vigencia-membresia-clubgamer?plataforma_id=1&usuario_id=${this.$store.getters['userId']}`)
                    this.membresiaProfile = a.data.data;
                    
                    
                } catch (err) { console.log(err) }
            },
        },
        computed: {
            avatar() {
                return this.userProfile.avatar === null ? require('@/assets/images/views/create_profile/avatar-default.png') : this.userProfile.avatar
            }
        },  
        mounted(){
            sdkclaro.getInstance().switchGoBackButton(true)
            this.getProfile()
            this.getMembresiaProfile()
            this.trackLog({ 
                action: 'Ingreso a perfil', 
                medio: 'Botón', 
                sectionId: 7, 
                elementId: null, 
            })
        },
    }
</script>
<template>
    <div class="create-profile-view app-view relative pt-30">
        <header-component>
            <h1 class="tx-24 tx-center mb-0">
                Mi Perfil
            </h1>
            <p class="tx-center mb-0">
                {{ userProfile.puntos }} puntos
            </p>
        </header-component>

        <div class="container">
            <div class="flex align-center pl-4 mb-6">
                <!--<div class="avatar h-24 w-24 rounded-100pr bg-center bg-cover bg-no-repeat mr-9" :style="{ 'background-image': 'url(' + avatar + ')' }"></div>-->
                <p class="tx-20 tx-medium mb-0">
                    {{ userProfile.nombre }}<br>
                    <span style="font-size: 12px;">Vigencia de membresía: {{ membresiaProfile.fecha_fin | formatDate }}</span><br>
                    <span style="font-size: 12px;">Edad: {{ edad }}</span><br>            
                </p>

            </div>
            <div class="box box--white tx-medium py-9 px-11 rounded-10 mb-12">
                <p class="mb-0">
                    Plataforma de juegos preferida:
                </p>
                <p>
                    {{ userProfile.plataforma }}
                </p>
                <p class="mb-0">
                    Preferencia por videojuegos de tipo:
                </p>
                <p>
                    {{ userProfile.juegos }}
                </p>
                <p class="mb-0">
                    Streamer favorito:
                </p>
                <p>
                    {{ userProfile.referencias }}
                </p>
            </div>

            <!-- BUTTONS -->
            <my-button class="mb-3" label="Editar perfil" :sectionId="7" action="Editar perfil" path="/editar-perfil" link/>
            <my-button label="Historial de compras" :sectionId="7" action="Ir a historial de compras" path="/historial-de-compras" link/>
        </div>
    </div>
</template>
<style lang="scss" scoped>
    .box{
        background-color: rgba($color: #fff, $alpha: 0.35);
    }
    
    .avatar{
        box-shadow: 0 4px 8px rgba($color: #000, $alpha: 0.35);
    }
</style>