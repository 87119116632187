<script>
    import ControlsBackAndHome from '@/components/layout/ControlsBackAndHome.vue'
    import MyButton from '@/components/ui/MyButton.vue'
    import ModalScratchAndWin from '@/components/ModalScratchAndWin.vue'

    export default {
        components: { ControlsBackAndHome, MyButton, ModalScratchAndWin },
        data: () => ({
            modalShare: false,
            modalSend: false,
            modalWin: false,
        }),
        methods: {
            toggleModalShare(){
                this.modalShare = !this.modalShare
            },
            toggleModalSend(){
                this.modalSend = !this.modalSend
            },
            toggleModalWin(){
                this.modalWin = !this.modalWin
            },
            goToClaroApp(){
                window.open('https://play.google.com/store/apps/details?id=com.globalhitss.claro.pay&hl=es_MX&gl=US&pli=1', '_blank');
            }
        }
    }
</script>
<template>
    <div class="scratch-and-win-view app-view pt-15">
<!--         <div class="flex justify-end tx-bold gap-5 mb-10">
            <router-link to="/inicio/tienda">
                Regresar
            </router-link>
            <router-link to="/inicio/arena-zuggy">
                Inicio
            </router-link>
        </div>

        ¡Rasca y descubre la recompensa que preparamos especialmente para ti!
        <div class="t-box h-80 t-black my-10" @click="modal = true"></div>
        Gana increíbles premios todas las semanas, solo necesitas mantener activa tu membresía mensual. Recuerda renovarla cada mes.

        <div class="modal flex align-center" v-if="modal">
            <div class="modal-lightbox t-yellow max-w-100 p-20">
                <div class="modal-close" @click="modal = false">
                    &times;
                </div>
                ¡Felicidades! Has ganado $200 en tu monedero Steam
                <br>
                <br>
                [Imagen Steam con código 5678UTY]
                <br>
                <br>
                Registra el código y estarás listo para disfrutar y utilizarlo en lo que más quieras.
            </div>
        </div> -->
        <header class="container relative flex justify-between align-center mb-20">
            <controls-back-and-home class="-ml-4 z-1"/>

            <div class="absolute -mb-5 ">
                <h2 class="tx-24 tx-bold tx-center mb-0">
                    Rasca y Gana
                </h2>
                <img class="decore-shine" src="@/assets/images/layout/shine-1.png" alt="">
            </div>
        </header>   
        <div class="container">
            <p class="tx-18 mb-11">
                ¡Rasca el circulo y descubre la recompensa <br> que preparamos especialmente para ti!
            </p>

            <div class="relative w-fit mx-auto mb-11" @click="toggleModalWin()">
                <img src="@/assets/images/views/scratch_and_win/to-scratch.png" alt="">
                <img class="decore-shine absolute b-0 l-0 r-0 mx-auto -mb-1" src="@/assets/images/layout/shine-box.png" alt="">
            </div>

            <p class="tx-18 tx-center mb-12">
                Gana increíbles premios todas <br> las semanas, solo necesitas mantener <br> activa tu membresía mensual. <br>
                <strong>Recuerda renovarla cada mes.</strong>
            </p>

            <my-button class="mb-4" label="Comparte" @clicked="toggleModalShare()"/>
            <my-button label="Rasca de nuevo" @clicked="toggleModalSend()"/>
        </div>

        <!-- MODAL SHARE -->
        <transition name="fade">
            <modal-scratch-and-win @close="toggleModalShare()" v-if="modalShare">
                <p class="tx-share tx-20 tx-center tx-strong mb-12 mt-10">
                    Enviar invitación <br>
                    a mis amigos
                </p>
                <my-button class="btn-share mx-auto" label="Descargar la app" @clicked="goToClaroApp()"/>
            </modal-scratch-and-win>
        </transition>

        <!-- MODAL SEND -->
        <transition name="fade">
            <modal-scratch-and-win @close="toggleModalSend()" v-if="modalSend">
                <p class="tx-center my-10">
                    Para poder participar por más premios, <br>
                    manda una invitación exclusiva <br>
                    a uno de tus amigos. <strong>Así ambos podrán vivir <br>
                    la experiencia Club Gamer.</strong>
                </p>
            </modal-scratch-and-win>
        </transition>

        <!-- MODAL WIN -->
        <transition name="fade">
            <modal-scratch-and-win class="tx-center" @close="toggleModalWin()" v-if="modalWin">
                <p class="tx-share tx-18 tx-center tx-strong mt-3">
                    ¡Felicidades!
                </p>
                <p>
                    Has ganado $200 en tu monedero Steam
                </p>
                <my-button class="btn-share mx-auto mb-1" label="5678UTY"/>
                <p class="tx-medium">
                    Registra el código y estarás listo <br> para disfrutar y utilizarlo <br> en lo que más quieras.
                </p>
            </modal-scratch-and-win>
        </transition>
    </div>  
</template>
<style lang="scss" scoped>
    .tx-share{
        text-shadow:
            0 0 1px #fff,
            0 0 5px #fff,
    }
    .btn-share{
        max-width: 168px;
    }
</style>