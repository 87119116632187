<script>
    import axios from 'axios';
    import ModalAlert from '@/components/ModalAlert.vue';
    import TorneoItem from '@/components/TorneoItem.vue'
    export default {
        components: { TorneoItem, ModalAlert},
        data: () => ({
            restModal: false,
            modal: false,
            render: false,
            current: {},
            fielType: '',
        }),     
        methods: {
            modalResult(modal, restModal){
                this.restModal = restModal
                this.modal = modal
            },
            async getNew (){
                try{
                    const res = await axios.post(process.env.VUE_APP_SERVICE_URL + 'torneos-id?plataforma_id=1&sid=' + this.$route.params.id);
                    this.current = res.data
                    console.log(this.current)
                    //this.fielType = this.current.imagen.split('.').pop()
                } catch (err) { console.log(err) }
            },
            buscando(){
                var info = document.getElementById("info-c");
                var span_inco = info.getElementsByTagName("p");
                var span = info.getElementsByTagName("span");
 
                for (let item of span_inco) {
                    item.style.color = "";
                }
                for (let item of span) {
                    item.style.color = "";
                }
            }
        },
        mounted() {
            this.getNew();

            this.trackLog({ 
                action: 'Ingreso a lista de torneos', 
                medio: 'Banner', 
                sectionId: 2, 
                elementId: this.$route.params.id, 
            })

            console.log("Init: " + this.$route.params.id)
        }
    }
</script>

<template>
    <div class="new-detail-view">
        <div class="container">
            <div class="container">
                <torneo-item @modalResultInfo="modalResult($event)" class="mb-9" v-for="(item, idx) in current" :key="idx" :data="item"/>
            </div>
        </div>

        <!-- MODAL -->
        <transition name="fade">
                <modal-alert v-if="modal" @close="modal = false">
                    {{this.restModal}}
                    <div class="py-4 px-1" v-if="restModal">
                        <p class="tx-center tx-24 tx-bold mb-5">
                            Gracias por registrarte.
                        </p>
                        <div class="flex justify-center">
                            <button class="btn btn--success" @click="modal = false">
                                Aceptar
                            </button>
                        </div>
                    </div>
                    <div class="py-4 px-1" v-else>
                        <p class="tx-center tx-24 tx-bold mb-5">
                            Ya estas registrado.
                        </p>
                        <div class="flex justify-center">
                            <button class="btn btn--success" @click="modal = false">
                                Aceptar
                            </button>
                        </div>
                    </div>
                </modal-alert>
            </transition>
    </div>

    
</template>
<style lang="scss" scoped>
    .decore-shine{ max-width: initial;}
</style>
