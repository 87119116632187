<script>
    import axios from 'axios';

    export default {
        data: () => ({
            current: {},
            fielType: '',
        }),     
        methods: {
            async getNew (){
                try{
                    const res = await axios.post(process.env.VUE_APP_SERVICE_URL + 'detalle-evento-especial?plataforma_id=1&id=' + this.$route.params.id);
                    this.current = res.data
                    this.fielType = this.current.imagen.split('.').pop()
                } catch (err) { console.log(err) }
            },
            buscando(){
                var info = document.getElementById("info-c");
                var span_inco = info.getElementsByTagName("p");
                var span = info.getElementsByTagName("span");
 
                for (let item of span_inco) {
                    item.style.color = "";
                }
                for (let item of span) {
                    item.style.color = "";
                }
            }
        },
        mounted() {
            this.getNew();

            this.trackLog({ 
                action: 'Ingreso a detalle de evento especial', 
                medio: 'Banner', 
                sectionId: 2, 
                elementId: this.$route.params.id, 
            })

            setTimeout(function () { this.buscando(); }.bind(this), 100)
        }
    }
</script>

<template>
    <div class="new-detail-view">
        <div class="container">

            <div class="relative mb-5" v-if="fielType === 'mp4'">
                <video class="rounded-10 border-1" controls>
                    <source src="https://joy1.videvo.net/videvo_files/video/free/2013-08/large_watermarked/hd0992_preview.mp4" type="video/mp4">
                    Your browser does not support HTML video.
                </video>
                <img class="decore-shine absolute l-0 r-0 b-0 -mb-2 mx-auto" src="@/assets/images/layout/shine-banner.png" alt="">
            </div>

            <div class="relative bg-center bg-cover bg-no-repeat h-55 w-100pr rounded-10 border-1 border-white mb-5" :style="{ 'background-image': 'url(' + current.imagen + ')' }" v-else>
                <img class="decore-shine absolute l-0 r-0 b-0 -mb-2 mx-auto" src="@/assets/images/layout/shine-banner.png" alt="">
            </div>

            <p class="tx-20 tx-bold" v-html="current.titulo"></p>
            <!-- BODY -->
            <div class="tx-14 tx-medium mb-6 father-info" id="info-c" v-html="current.descripcion"></div>
        </div>
    </div>
</template>
<style scoped>
    .decore-shine{ max-width: initial;}

    .father-info{
        color: #fff !important;
    }

    .father-info p{
        color: #fff !important;
    }

    .father-info p span{
        color: #fff !important;
    }
</style>
