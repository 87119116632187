<script>
    export default {

    }
</script>
<template>
    <div class="modal absolute flex align-center">
        <div class="modal-lightbox w-100pr p-4 bg-center bg-cover bg-no-repeat rounded-10" :style="{'background-image' : `url(${require('@/assets/images/views/scratch_and_win/bg-modal.png')})`}">
            <div class="modal-close tx-white tx-52 mx-auto -mb-17" @click="$emit('close')">
                &times;
            </div>
            <slot></slot>
        </div>

    </div>
</template>
<style lang="scss" scoped>
    .modal-close{
        top: initial;
        left: 0;
        bottom: 0;
    }
</style>